import $ from 'jquery';
global.$ = jQuery;
import { custom } from './custom';
import Rails from '@rails/ujs';

$( document ).on('turbolinks:load', function() {
  try {
    $('.nav-ham-bar').click(function(){
      $('#sidebar').hide({ direction: "left" }, 1000);
      $('.content').animate({left: "50px"});
    });
    $('.hide_nav').click(function(){
      $('#sidebar').show({ direction: "right" }, 1000);
      $('.content').animate({left: "180px"});
    });
  }
  catch(err) {
    console.error(err);
  };

  window.removeGhostConcept = function(){
    $(this).closest('div').remove();
  };

  $("#current_date").closest('table').clone().appendTo( "#current_period" );

  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    enableTime: true,
    altFormat: "D, F j, Y | h:iK",
    dateFormat: "Y-m-d H:i",
  });

  flatpickr("[data-behavior='flatpickrdate']", {
    altInput: true,
    altFormat: "D, F j, Y",
    dateFormat: "Y-m-d",
  });

  flatpickr("[data-behavior='flatpickrtime']", {
    enableTime: true,
    noCalendar: true,
    format: "h:iK",
    altFormat: "h:iK",
    dateFormat: "h:iK",
  });

  window.inspectQuestion = function (question){
    $(question).toggle(300);
  };

  $("[id^=submit_btn_]").click(function(){
    var target = event.target.id.split("_")[2];
    var nextTarget = (parseFloat(target) + 1).toString();

    $("#inspection_question_" + target).attr( "class", "inspec_questions_edit" );
    window.inspectQuestion('#' + target + "_2");
    window.inspectQuestion('#' + nextTarget + "_2");
  });

  function createCookie(key, value) {
     let cookie = escape(key) + " = " + escape(value) + ";";
     document.cookie = cookie;
     console.log(cookie);
     console.log("Creating new cookie with key: " + key + " value: " + value);
  }
  createCookie("sport", "basketball");
  createCookie("icecream", "vanilla");

  // var form = document.getElementById('condenser-form');
  // form.onsubmit = function() {
  //   console.log("On-Submit Triggered");
  //   var formData = new FormData(form);
  //
  //   formData.append('file', file);
  //
  //   var xhr = new XMLHttpRequest();
  //   // Add any event handlers here...
  //   xhr.open('POST', form.getAttribute('action'), true);
  //   xhr.send(formData);
  //
  //   return false; // To avoid actual submission of the form
  // }

  $("#foh_location_select").change(function() {
    var selectedForm = $("#foh_location_select").val();
    $(".foh_messaging_forms").css("display", "none");

    $("#foh_" + selectedForm).css("display", "block");
    console.log(selectedForm);
  });

  $("#boh_location_select").change(function() {
    var selectedForm = $("#boh_location_select").val();
    $(".boh_messaging_forms").css("display", "none");

    $("#boh_" + selectedForm).css("display", "block");
    console.log(selectedForm);
  });

  function delay(fn, ms) {
    let timer = 0
    return function(...args) {
      clearTimeout(timer)
      timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
  }

  $('[id$=search_field]').keyup(delay(function (e) {
    submitSearch();
  }, 200));

  function submitSearch() {
    let form = document.getElementById('search_form');
    Rails.fire(form, 'submit');
  };

});
